import React from "react"
import GlobalStyle from "../containers/ShowcasePage/Common/global-styles"
import Banner from "../containers/ShowcasePage/Banner"
import HeaderMenuBS from "../containers/ShowcasePage/HeaderMenuBS"
import Demos from "../containers/ShowcasePage/Demos"
import Features from "../containers/ShowcasePage/Features"
import KeyFeatures from "../containers/ShowcasePage/KeyFeatures"
import Download from "../containers/ShowcasePage/Download"
import Footer from "../containers/ShowcasePage/Footer"
import SEO from "../components/seo"
import "../components/layout.css"
import Fonts from "../containers/ShowcasePage/Common/fonts"
import BuyNow from "../components/BuyNow"

const IndexPage = () => (
  <div>
    <Fonts />
    <GlobalStyle />
    <HeaderMenuBS />
    <main>
      <SEO
        title="Real Estate | GatsbyJS Landing Page Templates"
        description="Buy ReactJS Landing Page Templates built with GatsbyJS, React Bootstrap and Styled-Components"
      />
      <Demos />
      <Features />
      <KeyFeatures />
      <Download />
    </main>
    <Footer />
  </div>
)

export default IndexPage
